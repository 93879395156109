// extracted by mini-css-extract-plugin
export var aGrade = "index-module--aGrade--15a80";
export var activeEvent = "index-module--activeEvent--480ee";
export var appdown = "index-module--appdown--e2a9d";
export var background = "index-module--background--0c501";
export var background1 = "index-module--background1--0762c";
export var background2 = "index-module--background2--099a0";
export var btn = "index-module--btn--646d4";
export var category1 = "index-module--category1--4a84e";
export var category2 = "index-module--category2--36ce2";
export var category3 = "index-module--category3--584bc";
export var category4 = "index-module--category4--72b61";
export var categoryWrapper = "index-module--categoryWrapper--8fcb1";
export var dGrade = "index-module--dGrade--8cb31";
export var description = "index-module--description--c0179";
export var download = "index-module--download--be9f4";
export var event = "index-module--event--1ac02";
export var fadeIn = "index-module--fadeIn--4bbae";
export var flex_container = "index-module--flex_container--c7352";
export var flex_item = "index-module--flex_item--a960d";
export var flex_item1 = "index-module--flex_item1--ed97f";
export var footprint = "index-module--footprint--b47e2";
export var grade2 = "index-module--grade2--8339c";
export var grade3 = "index-module--grade3--8eba0";
export var grade4 = "index-module--grade4--bd951";
export var grade5 = "index-module--grade5--d0c76";
export var grid_container = "index-module--grid_container--b293b";
export var grid_item = "index-module--grid_item--a5b8a";
export var grid_item1 = "index-module--grid_item1--36d41";
export var grid_item2 = "index-module--grid_item2--7b1ec";
export var logo = "index-module--logo--686d8";
export var mContainer = "index-module--mContainer--eb318";
export var mReviewEval1SlideIn = "index-module--mReviewEval1SlideIn--74877";
export var mReviewEval2SlideIn = "index-module--mReviewEval2SlideIn--460d7";
export var mReviewEval3SlideIn = "index-module--mReviewEval3SlideIn--6de1b";
export var m_section_analysis = "index-module--m_section_analysis--1e92b";
export var m_section_analysis_hiddden = "index-module--m_section_analysis_hiddden--b8203";
export var m_section_sub1 = "index-module--m_section_sub1--3b715";
export var m_wave = "index-module--m_wave--56ad5";
export var mainSearchIcon = "index-module--mainSearchIcon--1383a";
export var pcContainer = "index-module--pcContainer--00e84";
export var pets = "index-module--pets--ff8c8";
export var phone = "index-module--phone--81161";
export var popUp = "index-module--popUp--7c36d";
export var reviewEval1 = "index-module--reviewEval1--83adf";
export var reviewEval1SlideIn = "index-module--reviewEval1SlideIn--cb55d";
export var reviewEval2 = "index-module--reviewEval2--65912";
export var reviewEval2SlideIn = "index-module--reviewEval2SlideIn--c04bc";
export var reviewEval3 = "index-module--reviewEval3--1942a";
export var reviewEval3SlideIn = "index-module--reviewEval3SlideIn--60361";
export var sGrade = "index-module--sGrade--96e16";
export var searchBox = "index-module--searchBox--94214";
export var searchButton = "index-module--searchButton--98681";
export var searchButtonPlaceholder = "index-module--searchButtonPlaceholder--2be02";
export var searchContainer = "index-module--searchContainer--54b7d";
export var searchPlaceholder = "index-module--searchPlaceholder--d15aa";
export var section1 = "index-module--section1--093b8";
export var section1_1 = "index-module--section1_1--1f37d";
export var section2 = "index-module--section2--266c0";
export var section2SlideIn = "index-module--section2SlideIn--bdbba";
export var section2_1 = "index-module--section2_1--eb80d";
export var section3 = "index-module--section3--747af";
export var section4 = "index-module--section4--568c4";
export var section5 = "index-module--section5--c390d";
export var section5FadeIn1 = "index-module--section5FadeIn1--c94b4";
export var section5FadeIn2 = "index-module--section5FadeIn2--9a4b2";
export var section5FadeIn3 = "index-module--section5FadeIn3--60c25";
export var section5FadeIn4 = "index-module--section5FadeIn4--640ec";
export var section7 = "index-module--section7--df649";
export var sectionPet = "index-module--sectionPet--0acd4";
export var section_main = "index-module--section_main--e57ad";
export var section_sub1 = "index-module--section_sub1--13b84";
export var section_sub2 = "index-module--section_sub2--660ac";
export var slidedown = "index-module--slidedown--95b72";
export var slideup = "index-module--slideup--a561d";
export var store = "index-module--store--26654";
export var storeWrapper = "index-module--storeWrapper--b4fa4";
export var textContent = "index-module--textContent--6987f";
export var textSubTitle = "index-module--textSubTitle--c646d";
export var textTitle = "index-module--textTitle--f4774";
export var wave = "index-module--wave--89506";
export var wrapper = "index-module--wrapper--5ca2c";
/**
 * Home Page
 */

import { Link, navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'src/components/layout';
import { Mobile, PC } from 'src/components/mediaQuery';
import axios from 'axios';
import { fetchGetAppEvent, RootState } from 'src/state/rootStore';

import * as styles from 'src/styles/index.module.css';
import { amplitudeLog } from 'src/Lib/ampletude';
import { getPlatform } from 'src/utils';
import { StaticImage } from 'gatsby-plugin-image';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { SEO } from 'src/components/seo';

// TODO: 모든 img tag -> StaticImage로 변경

const Home = ({ data }: any) => {
  const [productCount, setProductCount] = useState('');
  const currentEvent = useSelector((state: RootState) => state.appEvent);
  const dispatch = useDispatch();
  // set siteMetadata variables
  const { apiUrl, appLink, appLinkApple, appLinkGoogle, stage } = useSiteMetadata();
  const [position, setPosition] = useState(0);
  const [changeEventId, setChangeEventId] = useState(0);
  // TODO: redux
  const modalVisible = useSelector((state: RootState) => state.isNoticeModalVisible);

  const handleScroll = () => {
    const height = window.outerHeight;
    setPosition(window.pageYOffset + height);
  };

  // useEffect(() => {}, [])

  useEffect(() => {
    amplitudeLog(`Main View`, { type: getPlatform() });
    setPosition(window.outerHeight);
    // axios.get(`${apiUrl}/v2/boards/tester/latest`).then(res => {
    //   setCurrentEvent(res.data.data)
    // })
    // get change event id
    // axios
    //   .get(`${apiUrl}/v2/change-event/id`)
    //   .then(res => {
    //     setChangeEventId(res?.data?.data?.id)
    //   })
    //   .catch(err => {
    //     console.log("Fail to get change event ID")
    //   })
    const init = async () => {
      dispatch(fetchGetAppEvent());
    };
    init();

    Promise.all([
      axios.get(`${apiUrl}/v3/products?page=0&limit=0&order=latest`).then((res) => res.data.data.count as number),
      axios.get(`${apiUrl}/pet/v3/products?page=0&limit=0&order=latest`).then((res) => res.data.data.count as number),
    ]).then((counts) => {
      let countSum = counts[0] + counts[1];
      countSum = Math.round(countSum / 1000) * 1000;
      setProductCount(countSum.toLocaleString());
    });

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [changeEventId]);

  const handleDownClick = (eventName: string) => {
    return () => {
      if (eventName === 'm_foot') {
        amplitudeLog(`앱 설치 버튼`, {
          path: '메인 하단 고정',
          type: getPlatform(),
        });
      } else {
        amplitudeLog(`앱 설치 버튼`, {
          path: '메인 검색',
          type: getPlatform(),
        });
      }

      // app store/google play 분기
      // apple: appLinkApple, android: appLinkGoogle
      let gotoLink = appLink;
      if (eventName.includes('app')) {
        // apple
        gotoLink = appLinkApple;
      } else if (eventName.includes('play')) {
        // google
        gotoLink = appLinkGoogle;
      }
      window.open(gotoLink);
    };
  };

  const onClickSearch = async () => {
    navigate('/search');
    amplitudeLog('제품 검색 화면', {
      path: '메인화면 검색창',
      type: getPlatform(),
    });
  };

  const onClickEvents = async () => {
    navigate('/events');
  };

  return (
    <Layout pageTitle="홈">
      <PC className={styles.pcContainer}>
        <div className={styles.section_main}>
          <div className={styles.wrapper}>
            <StaticImage
              alt="섹션1 배경"
              src={'../../static/images/main/main_section1_2025.png'}
              className={styles.background}
            />
            <div style={{ paddingTop: '8%', paddingLeft: '4%' }}>
              <h2>안전한 육아, 살림을 위한</h2>
              <h2>No.1 유해 성분 분석 앱</h2>
              <p className={styles.description}>
                유아용품부터 세제, 샴푸, 치약, 펫 용품까지 <br />
                맘가이드로 안전성을 체크하세요.
              </p>
              <div className={styles.searchContainer}>
                <div className={styles.searchBox} onClick={onClickSearch}>
                  <p className={styles.searchPlaceholder}>성분이 궁금한 제품을 검색해보세요</p>
                </div>
                <div className={styles.searchButton} onClick={onClickSearch}>
                  <div className={styles.searchButtonPlaceholder}>검색하기</div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '80px',
                }}
              >
                <div className={styles.download}>앱 설치하기 &gt;</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img
                    alt="구글 플레이스토어 맘가이드 설치 링크"
                    onClick={handleDownClick('pc_playstore')}
                    src="/images/main/google_play.png"
                    className={styles.store}
                  />
                  <img
                    alt="앱스토어 맘가이드 설치 링크"
                    onClick={handleDownClick('pc_appstore')}
                    src={'/images/main/apple_store.png'}
                    className={styles.store}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* image left, section_sub1 */}
        <div className={styles.section_sub1}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/main_section1_1_2025.png'}
                  />
                </div>
              </div>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    <div>
                      안심 제품이 한눈에!
                      <br />
                      성분에 따른 제품 등급
                    </div>
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    <div>
                      유해 성분 없는 안심 제품
                      <br />
                      등급으로 쉽게 고르세요.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* image right, section_sub2 */}
        <div className={styles.section_sub2}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    가장 깐깐한
                    <br />
                    유해 성분 분석
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    <div>
                      EWG등급 뿐만 아니라 우리나라 식약처 및
                      <br />
                      환경부 등 국내외 10가지 이상 공신력 있는
                      <br />
                      기관의 평가 기준을 반영하여 정보를 제공합니다.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.grid_item}>
                {position > 1200 ? (
                  <StaticImage
                    alt="섹션2 배경"
                    src={'../../static/images/main/main_section2_2025.png'}
                    className={styles.background}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub1}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/main_section1_2_2025.png'}
                  />
                </div>
              </div>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    <div>임상 결과로 보는</div>
                    <div>제품별 효능·효과 정보</div>
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    과대 광고 걱정 끝!
                    <br />
                    필요한 제품을 정확하게 고르세요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub2}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div style={{ width: '100%' }}>
                  <div className={styles.textTitle}>
                    이번 달 유아 크림 1위는?
                    <br />
                    데이터로 산출한 깐깐한 랭킹!
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    <div>
                      ①성분 안전성 ②사용자 평가 ③최신 선호도를
                      <br />
                      모두 고려한 맘가이드 랭킹을 확인하세요.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.grid_item}>
                {position > 1200 ? (
                  <StaticImage
                    alt="섹션2 배경"
                    src={'../../static/images/main/main_section2_1_2025.png'}
                    className={styles.background1}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub1}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <StaticImage
                    alt="섹션2 배경"
                    src={'../../static/images/main/main_section2_2_2025.png'}
                    className={styles.background1}
                  />
                </div>
              </div>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    <div>월령·관심사별로 보는</div>
                    <div>우리 아이 맞춤 리뷰</div>
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    나와 비슷한 고민을 했던 엄빠들의 리뷰를
                    <br />
                    자녀 나이, 관심 분야 별로 쉽게 확인하세요.
                    <br />
                    *맘가이드는 가짜/광고성 리뷰를 철저히 검수해요!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub2}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    성분이 검토된 제품만, 무료로
                    <br />
                    체험단&증정 이벤트
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    국내 유일! “성분 안심 제품 체험단”
                    <br />
                    안심 제품 사용해 보고 깐깐하게 평가해 주세요.
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '375px' }}>
                      <Link to="/events/">
                        <StaticImage
                          alt="이벤트 더 보기"
                          src={'../../static/images/main/goToEvents.png'}
                          className={styles.btn}
                          style={{ width: '105px' }}
                        />
                      </Link>
                    </div>
                    <div style={{ height: '10px' }}></div>
                    {currentEvent?.thumbnail ? (
                      <div
                        style={{
                          margin: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          borderRadius: '5px',
                        }}
                      >
                        <Link to={`/events/${currentEvent.id}/`}>
                          <img alt="진행중인 이벤트" className={styles.activeEvent} src={currentEvent?.thumbnail} />
                        </Link>
                      </div>
                    ) : (
                      <div style={{ minHeight: 230 }}></div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.grid_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/main_section1_3_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub1}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <StaticImage
                    alt="섹션2 배경"
                    src={'../../static/images/main/main_section2_3_2025.png'}
                    className={styles.background1}
                  />
                </div>
              </div>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    <div> 안심 제품만, 초특가로</div>
                    <div> 맘가이드 핫딜</div>
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div className={styles.textContent}>
                    ✅ 인터넷 최저가보다 20%이상 할인
                    <br />
                    ✅ 전 제품 유해 성분 ZERO
                    <br />✅ 365일 무료 배송
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.section_sub2}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    유해성분 없는 육아∙살림 보장!
                    <br />
                    맘가이드 매거진
                  </div>
                  <div style={{ height: '30px' }}></div>
                  <div className={styles.textContent}>
                    산모 교실, 제품 리뷰/추천부터 생활 꿀팁까지!
                    <br />
                    자체 실험, 과학적 근거 기반 똑똑한 매거진
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div>
                    <Link to="/magazines/">
                      <StaticImage
                        alt="매거진 더 보기"
                        src={'../../static/images/main/goToTips.png'}
                        className={styles.btn}
                      />
                    </Link>
                  </div>
                </div>
              </div>{' '}
              <div className={styles.grid_item}>
                <StaticImage
                  alt="이벤트 썸네일"
                  className={styles.event}
                  src={'../../static/images/main/main_section1_4_2025.png'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sectionPet}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item}>
                <StaticImage
                  alt="펫 PC 섹션"
                  src={'../../static/images/main/main_pet_2025.png'}
                  className={styles.phone}
                />
              </div>
              <div className={styles.grid_item}>
                <div>
                  <div className={styles.textTitle}>
                    집사라면 나쁜 건 쓰지 않아!
                    <br />펫 용품 성분 분석
                  </div>
                  <div style={{ height: '20px' }}></div>
                  <div>
                    간지러워도 말 못하는 아이들을 위해
                    <br />
                    반려동물 용품도 성분 체크는 필수!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PC>

      <Mobile className={styles.mContainer}>
        <div className={styles.section_main}>
          <div className={styles.wrapper}>
            <div className={styles.grid_container}>
              <div className={styles.grid_item1}>
                <div className={styles.textTitle}>
                  안전한 육아, 살림을 위한
                  <br />
                  No.1 유해 성분 분석 앱
                </div>
                <div style={{ height: '20px' }}></div>
                <div className={styles.textContent}>
                  유아용품부터 세제, 샴푸, 치약, 펫 용품까지
                  <br />
                  맘가이드로 안전성을 체크하세요.
                </div>

                <div className={styles.searchContainer}>
                  <div className={styles.searchBox} onClick={onClickSearch}>
                    <p className={styles.searchPlaceholder}>성분이 궁금한 제품을 검색해보세요.</p>
                    <StaticImage
                      alt="돋보기 아이콘"
                      className={styles.mainSearchIcon}
                      src={'../../static/images/main/search_black.svg'}
                    />
                  </div>
                </div>

                <div className={styles.storeWrapper}>
                  <div className={styles.download}>앱 설치하기 &gt;</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img
                      alt="구글 플레이스토어 맘가이드 설치 링크"
                      onClick={handleDownClick('m_playstore')}
                      src={'/images/main/google_play.png'}
                      className={styles.store}
                    />
                    <img
                      alt="앱스토어 맘가이드 설치 링크"
                      onClick={handleDownClick('m_appstore')}
                      src={'/images/main/apple_store.png'}
                      className={styles.store}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.grid_item1}>
                <StaticImage
                  alt="섹션1 모바일 배경"
                  src={'../../static/images/main/m_main_section1_2025.png'}
                  className={styles.background}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.m_section_sub1}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>안심 제품이 한눈에!</div>
                <div className={styles.textTitle}>성분에 따른 제품 등급</div>
                <div className={styles.textContent} style={{ marginTop: '40px' }}>
                  유해 성분 없는 안심 제품
                </div>
                <div className={styles.textContent}>등급으로 쉽게 고르세요.</div>
              </div>
              <div style={{ height: '40px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub1_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.m_section_sub1} style={{ height: '720px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '720px' }}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>가장 깐깐한</div>
                <div className={styles.textTitle}>유해 성분 분석</div>
                <div className={styles.textContent} style={{ marginTop: '20px' }}>
                  EWG등급 뿐만 아니라 우리나라 식약처 및
                </div>
                <div className={styles.textContent}>환경부 등 국내외 10가지 이상 공신력 있는</div>
                <div className={styles.textContent}>기관의 평가 기준을 반영하여 정보를 제공합니다.</div>
              </div>
              <div style={{ height: '20px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub2_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.m_section_sub1} style={{ height: '720px', backgroundColor: '#FAFAFA' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '720px' }}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>임상 결과로 보는</div>
                <div className={styles.textTitle}>제품별 효능·효과 정보</div>
                <div className={styles.textContent} style={{ marginTop: '20px' }}>
                  과대 광고 걱정 끝!
                </div>
                <div className={styles.textContent}> 필요한 제품을 정확하게 고르세요</div>
              </div>
              <div style={{ height: '20px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub3_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.m_section_sub1} style={{ height: '720px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>이번 달 유아 로션 1위는?</div>
                <div className={styles.textTitle}>데이터로 산출한 깐깐한 랭킹!</div>

                <div className={styles.textContent} style={{ marginTop: '40px' }}>
                  ①성분 안전성 ②사용자 평가 ③최신 선호도를
                </div>
                <div className={styles.textContent}>모두 고려한 맘가이드 랭킹을 확인하세요.</div>
              </div>
              <div style={{ height: '40px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub4_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.m_section_sub1} style={{ height: '720px', backgroundColor: '#FAFAFA' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '720px' }}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>월령·관심사별로 보는</div>
                <div className={styles.textTitle}>우리 아이 맞춤 리뷰</div>

                {/* <div className={styles.textSubTitle}>먼저 써본 엄빠들의 실사용 리뷰</div> */}
                <div className={styles.textContent} style={{ marginTop: '20px' }}>
                  나와 비슷한 고민을 했던 엄빠들의 리뷰를
                </div>
                <div className={styles.textContent}>자녀 나이, 관심 분야 별로 쉽게 확인하세요.</div>
                <div className={styles.textContent}>*맘가이드는 가짜/광고성 리뷰를 철저히 검수해요!</div>
              </div>
              <div style={{ height: '20px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub5_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.m_section_sub1} style={{ height: '1018px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '1018px' }}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>성분이 검토된 제품만, 무료로</div>
                <div className={styles.textTitle}>체험단&증정 이벤트</div>
                <div className={styles.textContent} style={{ marginTop: '20px' }}>
                  국내 유일! “성분 안심 제품 체험단”
                </div>
                <div className={styles.textContent}>안심 제품 사용해 보고 깐깐하게 평가해 주세요.</div>
              </div>
              <div style={{ height: '20px' }}></div>
              <div>
                <StaticImage
                  alt="이벤트 썸네일"
                  className={styles.event}
                  src={'../../static/images/main/m_section_sub6_2025.png'}
                />
              </div>
              {currentEvent?.thumbnail ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '5px',
                  }}
                >
                  <Link to={`/events/${currentEvent.id}/`}>
                    <img alt="진행중인 이벤트" className={styles.activeEvent} src={currentEvent?.thumbnail} />
                  </Link>
                </div>
              ) : (
                <div style={{ minHeight: 230 }}></div>
              )}
              <div style={{ height: '30px' }}></div>
              <div>
                <Link to="/events/">
                  <StaticImage
                    alt="이벤트 더 보기"
                    src={'../../static/images/main/m_goToEvents.png'}
                    className={styles.btn}
                    style={{
                      width: '92px',
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.m_section_sub1}
          style={{
            height: '720px',
            backgroundColor: 'rgba(82, 217, 201, 0.1)',
          }}
        >
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '720px' }}>
              <div className={styles.flex_item}>
                {/* <div className={styles.textSubTitle}>안심 성분은 기본, 육아∙생활용품</div> */}
                <div className={styles.textTitle}> 안심 제품만, 초특가로</div>
                <div className={styles.textTitle}> 맘가이드 핫딜</div>

                <div className={styles.textContent} style={{ marginTop: '20px' }}></div>
                <div className={styles.textContent}>✅ 인터넷 최저가보다 20%이상 할인</div>
                <div className={styles.textContent}>✅ 전 제품 유해 성분 ZERO ✅ 365일 무료 배송</div>
              </div>
              <div style={{ height: '20px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub7_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.m_section_sub1} style={{ height: '720px', backgroundColor: '#FFFFFF' }}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container} style={{ height: '720px' }}>
              <div className={styles.flex_item}>
                <div className={styles.textSubTitle}>유해성분 없는 육아∙살림 보장!</div>
                <div className={styles.textTitle}>맘가이드 매거진</div>
                <div className={styles.textContent} style={{ marginTop: '20px' }}>
                  산모 교실, 제품 리뷰/추천부터 생활 꿀팁까지!
                </div>
                <div className={styles.textContent}>자체 실험, 과학적 근거 기반 똑똑한 매거진</div>
              </div>
              <div style={{ height: '10px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.event}
                    src={'../../static/images/main/m_section_sub8_2025.png'}
                  />
                </div>
              </div>
              <div>
                <Link to="/magazines/">
                  <StaticImage
                    alt="매거진 더 보기"
                    src={'../../static/images/main/m_goToTips.png'}
                    className={styles.btn}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionPet}>
          <div className={styles.wrapper}>
            <div className={styles.flex_container}>
              <div className={styles.flex_item}>
                <div className={styles.textTitle}>집사라면 나쁜 건 쓰지 않아!</div>
                <div className={styles.textTitle}>펫 용품 성분 분석</div>
                <div className={styles.textContent} style={{ marginTop: '40px' }}>
                  간지러워도 말 못하는 아이들을 위해
                </div>
                <div className={styles.textContent}>반려동물 용품도 성분 체크는 필수!</div>
              </div>
              <div style={{ height: '40px' }}></div>
              <div className={styles.flex_item}>
                <div>
                  <StaticImage
                    alt="이벤트 썸네일"
                    className={styles.phone}
                    src={'../../static/images/main/m_section_pet1_2025.png'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {position > 1300 ?  */}
        <div onClick={handleDownClick('m_foot')} className={styles.appdown}>
          <div
            style={{
              fontFamily: 'Nanum Gothic',
              fontWeight: '600',
              fontSize: '18.5px',
              lineHeight: '21.9px',
            }}
          >
            맘가이드 앱 다운받기
          </div>
          <div style={{ width: '20px' }}></div>
          <div>
            <StaticImage
              alt="섹션8 모바일 배경 2"
              src={'../../static/images/main/down_arrow.png'}
              style={{ width: '20px', height: '23px' }}
            />
          </div>
        </div>
      </Mobile>
    </Layout>
  );
};

export const Head = ({ location, params, data, pageContext }: any) => {
  return <SEO title="홈"></SEO>;
};

export default Home;
